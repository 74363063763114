export const getDisplayRowsNumber = (gridChildrens: any, minRowsNumber: number): number => { 
  
  let displayRowsNumber = 0;
  gridChildrens.forEach((fields) => { 
    let valueCounter = 0;
    fields.forEach((field) => {
      if (field.Value) {
        valueCounter++;
      }
    })
    if (valueCounter > 0) { 
      displayRowsNumber++
    }
  })
  return displayRowsNumber > minRowsNumber ? displayRowsNumber : minRowsNumber;

}