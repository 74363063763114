import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useField, useFormikContext } from 'formik';
import { DataCollectionPageModel } from '../../../pages/Assessment/types';
import { TextInput } from '../../Form/TextInput';
import { Checkbox } from '../../Form/Checkbox';
import { NumberInput } from '../../Form/NumberInput';
import { DateInput } from '../../Form/DateInput';
import { Textarea } from '../../Form/Textarea';
import { SelectInput } from '../../Form/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssessment,
  postAssessment,
} from '../../../store/assessmentSingle/actions';
import { SaveAssessmentModal } from '../../SaveAssessmentModal';
import { Grid } from '../../Grid';
import { IAppState } from '../../../store';
import { getApiAssessment } from '../../../store/assessment/actions';
import { useTranslation } from 'react-i18next';
import { translateTabContentText } from 'utils/helpers/useTranslation';
import { find } from 'lodash';
import { checkAllConditionsFollow } from 'utils/helpers/useCondition';
import { showField } from 'utils/helpers/showField';
import { Image } from './Image';
import { StaticMediaFields } from 'components/StaticMediaFields/StaticMediaFields';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { ImageInHeader } from 'components/Grid/Image';
import {
  Calculate,
  isCalculationResultField,
} from 'utils/helpers/calculation.functions';
import CalculationField from 'components/calculations/CalculationField';
import MirroredField from 'components/MirroredField/MirroredField';
import { getMirroredFieldValue } from 'utils/helpers/mirrored.functions';
import MultiSelect from 'components/MultiSelect';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';

export const fieldsConfig = {
  Text: TextInput,
  Date: DateInput,
  Checkbox: Checkbox,
  Selection: SelectInput,
  Number: NumberInput,
  Grid: Grid,
  Textarea: Textarea,
  Image: Image,
  StaticMedia: StaticMediaFields,
  ImageUploadComponent: ImageUpload,
  Calculated: CalculationField,
  Mirror: MirroredField,
  MultiSelection: MultiSelect,
};

interface IProps {
  newDataToPost?: any;
  data: DataCollectionPageModel;
  fieldsDisabled: boolean;
  pageNum: number;
  assId: number;
  children: any;
  translations?: any;
  newData?: any;
  setNewData?: React.Dispatch<React.SetStateAction<any>>;
  initialValues?: any;
  changedFieldsId?: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  setCurrentTab?: React.Dispatch<React.SetStateAction<any>>;
  setFormikVal?: React.Dispatch<React.SetStateAction<any>>;
  setUserHandleSaveData?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentId?: React.Dispatch<React.SetStateAction<any>>;
}

const TabContent = ({
  newDataToPost,
  setFormikVal,
  setUserHandleSaveData,
  setCurrentTab,
  changedFieldsId,
  setChangedFieldsId,
  translations,
  data,
  fieldsDisabled,
  pageNum,
  assId,
  children,
  newData,
  setNewData,
  initialValues,
  setCurrentId,
}: IProps) => {
  const dispatch = useDispatch();
  const { Pages, ID, StatusFlag } = useSelector(
    (state: IAppState) => state.singleAssessment.data,
  ).find(ass => ass.Pages.some(p => p.ID === data.ID));
  const { errors, values } = useFormikContext();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();
  setCurrentTab(Pages);
  setFormikVal(values);
  setCurrentId(ID);
  const onSaveButtonClick = () => {
    if (data.Fields.some(f => errors[f.Template.Name])) {
      return;
    }
    setPopupOpen(true);
  };
  const blockClass = 'column block-row';
  // data.Template.Description === 'Flock Details'
  //   ? 'column block-row'
  //   : 'block-row align-items-end';

  useEffect(() => {}, [newData]);

  const handleSaveClick = async () => {
    const dataToPost = [];
    let objEmpty = [];
    Pages.forEach(p =>
      p.Fields.forEach(f => {
        if (f.Template.FieldType.Name === 'Grid') {
          if (f.Template.Validator.MaxRows > f.GridChildren.length) {
            let lastRow = f.GridChildren[f.GridChildren.length - 1];
            if (lastRow) {
              objEmpty = [];
              let lastCeil = lastRow[lastRow?.length - 1];
              let id = lastCeil?.ID + 1;
              f.GridChildren[f.GridChildren.length - 1]?.forEach(item => {
                let newObj = {
                  ...item,
                  ID: id++,
                  Value: null,
                  GridRow: item.GridRow + 1,
                };
                objEmpty.push(newObj);
              });
            }
          }
          f.GridChildren.forEach(fieldsArray => {
            fieldsArray.forEach(field => {
              field.Value = (values[f.Guid] &&
                values[f.Guid][field.GridRow] &&
                values[f.Guid][field.GridRow][field.Template.Name]) as
                | string
                | null;
            });
          });
          f.GridChildren.push(objEmpty);
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({ ...f });
          }
        } else {
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({
              ...f,
              Value: values[f.Guid] as string,
            });
          }
        }
      }),
    );
    await dispatch(postAssessment(dataToPost, false));
    setChangedFieldsId([]);
    setUserHandleSaveData(true);
    // dispatch(getAssessment(`${ID}`));
  };

  const onCloseSavePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="tab-content">
      {children}
      <div
        className="tab-pane active"
        id="general"
        role="tabpanel"
        aria-labelledby="general-tab"
      >
        <div className="assessment-block">
          <h4 className="block-subtitle">
            {translateTabContentText(data.Template.Description, translations)}{' '}
          </h4>
          <div className={blockClass}>
            {data.Fields.map((field, index) => {
              const visible = field.Template.InitiallyVisibleOnLoad;
              const noDisplayConditions =
                field.Template.DisplayConditions.length === 0 ||
                field.Template.DisplayConditions === undefined ||
                field.Template.DisplayConditions === null;
              const conditionToShow =
                visible ||
                (!visible && noDisplayConditions) ||
                (!visible &&
                  !noDisplayConditions &&
                  checkAllConditionsFollow(field, newData));

              const isShowField = showField(
                field.Template.Archived,
                field.Template.VisibleExternally,
              );
              const isCalculatedFiled = isCalculationResultField(field);
              let parentMirrorDataGuid = { name: '', options: [] };
              if (
                field.Template.FieldType.Name &&
                field.Template.Parameters.FieldTemplateToMirror_ID
              ) {
                parentMirrorDataGuid = getMirroredFieldValue(
                  field.Template.Parameters.FieldTemplateToMirror_ID,
                  newData,
                  null,
                );
              }
              if (fieldsConfig[field.Template.FieldType.Name]) {
                let Component;
                if (field.Template.FieldType.Name === 'Grid') {
                  return (
                    <div
                      className={!conditionToShow || !isShowField ? 'hide' : ''}
                    >
                      <Grid
                        setChangedFieldsId={setChangedFieldsId}
                        newData={newData}
                        allFields={data}
                        translations={translations}
                        data={field}
                        key={field.Guid}
                        pageNum={pageNum}
                        assId={assId}
                      />
                    </div>
                  );
                }
                if (
                  field.Template.FieldType.Name === 'Text' &&
                  field.Template.Parameters.MultiLine
                ) {
                  Component = fieldsConfig['Textarea'];
                } else if (
                  field.Template.FieldType.Name &&
                  field.Template.FieldType.Discriminator === 'ImageFieldType'
                ) {
                  Component = fieldsConfig['ImageUploadComponent'];
                } else {
                  Component = fieldsConfig[field.Template.FieldType.Name];
                }
                if (field.Template.FieldType.Name === 'Selection') {
                  const isShowField = showField(
                    field.Template.Archived,
                    field.Template.VisibleExternally,
                  );

                  return (
                    <div
                      className={!conditionToShow || !isShowField ? 'hide' : ''}
                    >

                      {
                        <ImageInHeader
                          template={field.Template}
                          HasImage={field.Template.HasImage}
                          imageID={field.Template.ID}
                          showRowsAsColumns={false}
                        />
                      }
                      <SelectInput
                        setChangedFieldsId={setChangedFieldsId}
                        setNewData={setNewData}
                        newData={newData}
                        oneField={field}
                        translations={translations}
                        key={field.Guid}
                        name={field.Guid}
                        conditionToShow={conditionToShow}
                        OriginalHeight={field.Template?.OriginalHeight}
                        OriginalSize={field.Template?.OriginalSize}
                        OriginalWidth={field.Template?.OriginalWidth}
                        ImageHeight={field.Template?.ImageHeight}
                        ImageWidth={field.Template?.ImageWidth}
                        imageFile={field.Template?.ImageFile}
                        MaintainAspectRatio={
                          field.Template?.MaintainAspectRatio
                        }
                        labelText={translateTabContentText(
                          field.Template.DisplayName,
                          translations,
                        )}
                        options={field.Template.Parameters.ValuesList?.Values.map(
                          o => ({
                            value: o.ID,
                            label: o.Name,
                          }),
                        )}
                        disabled={fieldsDisabled}
                        required={field.Template.Required}
                      />
                    </div>
                  );
                }
                if (field.Template.FieldType.Name === 'MultiSelection') {
                  const isShowField = showField(
                    field.Template.Archived,
                    field.Template.VisibleExternally,
                  );

                  return (
                    <div
                      className={!conditionToShow || !isShowField ? 'hide' : ''}
                    >
                      {
                        <ImageInHeader
                          template={field.Template}
                          HasImage={field.Template.HasImage}
                          imageID={field.Template.ID}
                          showRowsAsColumns={false}
                        />
                      }
                      <MultiSelect
                        setChangedFieldsId={setChangedFieldsId}
                        setNewData={setNewData}
                        newData={newData}
                        oneField={field}
                        translations={translations}
                        key={field.Guid}
                        name={field.Guid}
                        conditionToShow={conditionToShow}
                        OriginalHeight={field.Template?.OriginalHeight}
                        OriginalSize={field.Template?.OriginalSize}
                        OriginalWidth={field.Template?.OriginalWidth}
                        ImageHeight={field.Template?.ImageHeight}
                        ImageWidth={field.Template?.ImageWidth}
                        imageFile={field.Template?.ImageFile}
                        MaintainAspectRatio={
                          field.Template?.MaintainAspectRatio
                        }
                        labelText={translateTabContentText(
                          field.Template.DisplayName,
                          translations,
                        )}
                        options={field.Template.Parameters.ValuesList?.Values.map(
                          o => ({
                            value: o.ID,
                            label: o.Name,
                          }),
                        )}
                        disabled={fieldsDisabled}
                        required={field.Template.Required}
                      />
                    </div>
                  );
                }
                return (
                  <div className="component-container">
                    {field.Template?.ImageFile &&
                      field.Template.FieldType.Name === 'Checkbox' && (
                        <img
                          src={`data:image/png;base64, ${field.Template?.ImageFile}`}
                          alt="img"
                        />
                      )}
                    <div
                      className={!conditionToShow || !isShowField ? 'hide' : ''}
                      onClick={() => {
                        setChangedFieldsId(prev => {
                          if (!prev.includes(field.Guid)) {
                            return [...prev, field.Guid];
                          }
                          return prev;
                        });
                      }}
                      onChange={() => {
                        setChangedFieldsId(prev => {
                          if (!prev.includes(field.Guid)) {
                            return [...prev, field.Guid];
                          }
                          return prev;
                        });
                      }}
                    >
                      {
                        <ImageInHeader
                          template={field.Template}
                          HasImage={field.Template.HasImage}
                          imageID={field.Template.ID}
                          showRowsAsColumns={false}
                          isTabContent={true}
                        />
                      }
                      <div
                        className={isCalculatedFiled ? 'calculated-field' : ''}
                      >
                        <Component
                          setChangedFieldsId={setChangedFieldsId}
                          OriginalHeight={field.Template?.OriginalHeight}
                          OriginalSize={field.Template?.OriginalSize}
                          OriginalWidth={field.Template?.OriginalWidth}
                          ImageHeight={field.Template?.ImageHeight}
                          ImageWidth={field.Template?.ImageWidth}
                          data={data}
                          MaintainAspectRatio={
                            field.Template?.MaintainAspectRatio
                          }
                          fieldID={field.ID}
                          gridColumnIndex={0}
                          template={field.Template}
                          oneField={field}
                          fieldData={field}
                          newData={newData}
                          key={field.Guid}
                          parentMirrorData={parentMirrorDataGuid}
                          name={field.Guid}
                          labelText={translateTabContentText(
                            field.Template.DisplayName,
                            translations,
                          )}
                          options={field.Template.Parameters.ValuesList?.Values.map(
                            o => ({
                              value: o.ID,
                              label: o.Name,
                            }),
                          )}
                          imageFile={field.Template?.ImageFile}
                          toolTipText={translateTabContentText(
                            field.Template.HelpText,
                            translations,
                          )}
                          postfixText={
                            field.Template?.Parameters?.MeasureDetail
                              ?.PostfixText
                          }
                          disabled={fieldsDisabled}
                          required={field.Template.Required}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="save-button_wrapper">
              <button
                className="btn action-primary save-btn"
                onClick={onSaveButtonClick}
                disabled={StatusFlag === 2 || StatusFlag === 5}
              >
                {t('AP_save')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <SaveAssessmentModal
        onSave={handleSaveClick}
        onExit={onCloseSavePopup}
        isOpen={isPopupOpen}
        text={t('AP_cont?')}
        cancelButtonText={t('AP_cancel')}
        primaryButtonText={t('AP_save_changes')}
      />
    </div>
  );
};
export { TabContent };
